<template>
  <div class="">
    <Breadcrumbs title="Create User" main="Users"/>
    <div class="container-fluid">
      <div class="row" v-if="user">
        <div class="col-md-12">

          <div class="card">

            <form @submit.prevent="submitCreateForm" class="card-body" data-parsley-validate>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Username <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model.lazy="user.username" class="form-control" :state="hasError('username')"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('username')">{{getError('username')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>First Name <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model.lazy="user.first_name" class="form-control" :state="hasError('first_name')"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('first_name')">{{getError('first_name')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Last Name <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model.lazy="user.last_name" class="form-control" :state="hasError('last_name')"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('last_name')">{{getError('last_name')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>NIC <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input @change="nicChanged" v-model="user.nic" class="form-control" minlength="10" maxlength="12" :state="hasError('nic')" required></b-form-input>
                  <b-form-invalid-feedback :state="hasError('nic')">{{getError('nic')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Mobile <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.phone_mobile" class="form-control" minlength="9" maxlength="9" placeholder="Last 9 digits" :state="hasError('phone_mobile')" required></b-form-input>
                  <b-form-invalid-feedback :state="hasError('phone_mobile')">{{getError('phone_mobile')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Phone (Home)</strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.phone_home" class="form-control" minlength="9" maxlength="10" :state="hasError('phone_home')"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('phone_home')">{{getError('phone_home')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Email Address</strong></div>
                <div class="col-sm-9">
                  <b-form-input v-model="user.email" :state="hasError('email')" class="form-control"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('email')">{{getError('email')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Address Line 1</strong></div>
                <div class="col-sm-9">
                  <b-form-textarea v-model="user.address_line_1" :state="hasError('address_line_1')" class="form-control"></b-form-textarea>
                  <b-form-invalid-feedback :state="hasError('address_line_1')">{{getError('address_line_1')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Address Line 2</strong></div>
                <div class="col-sm-9">
                  <b-form-textarea v-model="user.address_line_2" :state="hasError('address_line_2')" class="form-control"></b-form-textarea>
                  <b-form-invalid-feedback :state="hasError('address_line_2')">{{getError('address_line_2')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Status <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-select v-model="user.status" :options="statusOptions" :state="hasError('status')" value-field="id" text-field="text" class="border">
                    <template #first>
                      <b-form-select-option :value="null" disabled>-- Select an institute --</b-form-select-option>
                    </template>
                  </b-form-select>
                  <b-form-invalid-feedback :state="hasError('status')">{{getError('status')}}</b-form-invalid-feedback>
                </div>
              </div>





              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Institutes <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <div v-if="institutes" class="animate-chk">
                    <div class="row">
                      <div class="checkbox-animated col-12 col-md-6 col-lg-4" v-for="(institute, index) in institutes" :key="index">
                        <label class="d-block text-capitalize">
                          <input class="checkbox_animated" name="institutes" type="checkbox"
                                 v-model="user.institutes"
                                 :checked="user.institutes.includes(institute.id)"
                                 :value="institute.id"
                          > {{institute.text}}
                        </label>
                      </div>
                    </div>
                  </div>
                  <b-form-invalid-feedback :state="hasError('institutes')">{{getError('institutes')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right">
                  <strong>Roles <span class="font-danger">*</span></strong>
                </div>
                <div class="col-sm-9">
                  <div v-if="user_roles.length" class="animate-chk">
                    <div class="row">
                      <div class="checkbox-animated col-12 col-md-6 col-lg-4" v-for="(role, index) in user_roles" :key="index">
                        <label class="d-block text-capitalize">
                          <input class="checkbox_animated" name="role" type="checkbox"
                                 v-model="user.roles"
                                 :checked="user.roles.includes(role.id)"
                                 :value="role.id"
                          > {{role.text}}
                        </label>
                      </div>
                    </div>
                  </div>
                  <b-form-invalid-feedback :state="hasError('roles')">{{getError('roles')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row" v-if="user.roles.length" v-show="showSubjectSelections">
                <div class="col-sm-3 col-form-label text-lg-right">
                  <strong>Subjects <span class="font-danger">*</span></strong>
                </div>
                <div class="col-sm-9">
                  <div v-if="subjects.length" class="animate-chk">
                    <div class="row">
                      <div class="checkbox-animated col-12 col-md-6 col-lg-4" v-for="(subject, index) in subjects" :key="index">
                        <label class="d-block">
                          <input class="checkbox_animated" name="role" type="checkbox"
                                 v-model="user_subjects"
                                 :checked="user_subjects.includes(subject.id)"
                                 :value="subject.id"
                          > <span class="text-capitalize">{{subject.text}}</span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <b-form-invalid-feedback :state="hasError('subjects')">{{getError('subjects')}}</b-form-invalid-feedback>
                </div>
              </div>



              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Password <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input type="password" v-model.lazy="user.password" class="form-control" :state="hasError('password')"></b-form-input>
                  <b-form-invalid-feedback :state="hasError('password')">{{getError('password')}}</b-form-invalid-feedback>
                </div>
              </div>

              <div class="form-group row">
                <div class="col-sm-3 col-form-label text-lg-right"><strong>Confirm Password <span class="font-danger">*</span></strong></div>
                <div class="col-sm-9">
                  <b-form-input type="password" v-model.lazy="user.password_confirmation" class="form-control"></b-form-input>
                </div>
              </div>

              <div class="card-footer text-center">
                <b-button class="btn-square m-r-10 text-uppercase" variant="primary" type="submit">Submit</b-button>
                <b-button class="btn-square text-uppercase" variant="light" @click="resetFormData">Reset</b-button>
              </div>

            </form>

          </div>

        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CRUD_API from '@/services/crud'
import {mapGetters} from 'vuex'
export default {
  name: "users-create",
  mounted() {
    this.$store.dispatch('auth/getUser')
    this.$store.dispatch('select/fetchRecords', { id: 'roles' })
    this.$store.dispatch('select/fetchRecords', { id: 'institutes' })
    this.$store.dispatch('select/fetchRecords', { id: 'subjects' })
  },
  computed: {
    showSubjectSelections(){ return !!(this.user.roles.includes(4) > 0 || this.user.roles.includes(5) > 0 ) },
    ...mapGetters({
      institutes: 'select/institutes',
      subjects: 'select/subjects',
      user_roles: 'select/roles',
      logged_in_user: 'auth/user'
    })
  },
  data() {
    return {
      errorBag: null,
      user_subjects: [],
      user: {
        id: null,
        nic: null,
        email: null,
        dob: null,
        gender: 0,
        username: null,
        first_name: null,
        last_name: null,
        phone_home: null,
        phone_mobile: null,
        address_line_1: null,
        address_line_2: null,
        password: '',
        password_confirmation: '',
        status: 1,
        institutes: [],
        roles: [],
        subjects: [],
      },
      statusOptions: [
        { id: 0, text: 'Active' },
        { id: 1, text: 'Pending' },
        { id: 2, text: 'Suspended' },
      ],
    }
  },
  methods: {
    clearErrors(){ this.errorBag = []; },
    hasError(ifn){ if (this.errorBag) { return !this.errorBag.hasOwnProperty(ifn); } return null; },
    getError(ifn){ if(this.errorBag) { if (!this.hasError(ifn)) { return this.errorBag[ifn][0]; } } return null; },
    resetFormData() {
      this.user.id = null
      this.user.nic = null
      this.user.email = null
      this.user.dob = null
      this.user.gender = 0
      this.user.username = null
      this.user.first_name = null
      this.user.last_name = null
      this.user.phone_home = null
      this.user.phone_mobile = null
      this.user.address_line_1 = null
      this.user.address_line_2 = null
      this.user.password = ''
      this.user.password_confirmation = ''
      this.user.status = 1
      this.user.institutes = []
      this.user.roles = []
    },
    submitCreateForm() {
      this.clearErrors()
      this.nicChanged()
      try {
        CRUD_API.create('/api/backend/pages/users', this.user)
            .then((resp) => {
              if (resp.data.success) {
                this.resetFormData()
                this.$toasted.success("User account created successfully.")
              } else {
                this.errorBag = resp.data.data
                this.$toasted.error(resp.data.message)
              }
            })
            .catch((error) => {
              // this.errorBag = error.response.data.data
              this.$toasted.error(error.data.message)
            })
      } catch (e) {
        console.log(e)
      }
    },
    nicChanged() {
      let dayText = 0;
      let year = "";
      let month = "";
      let day = "";
      if (this.user.nic.length !== 10 && this.user.nic.length !== 12) {
        this.$toasted.show('Invalid NIC NO', {
          fullWidth: true,
          fitToScreen: true,
          position: 'top-center',
          type: 'error',
        })
      }
      else {
        // Year
        if (this.user.nic.length === 10) {
          year = "19" + this.user.nic.substr(0, 2);
          dayText = parseInt(this.user.nic.substr(2, 3));
        } else {
          year = this.user.nic.substr(0, 4);
          dayText = parseInt(this.user.nic.substr(4, 3));
        }

        // Gender
        if (dayText > 500) {
          this.user.gender = 1;
          dayText = dayText - 500;
        } else {
          this.user.gender = 0;
        }

        // Day Digit Validation
        if (dayText < 1 && dayText > 366) {
          // Invalid NIC NO
          this.user.dob = null
          this.$toasted.show('Invalid NIC NO', {
            fullWidth: true,
            fitToScreen: true,
            position: 'top-center',
            type: 'error',
          })
        } else {

          //Month
          if (dayText > 335) {
            day = dayText - 335;
            month = "12";
          }
          else if (dayText > 305) {
            day = dayText - 305;
            month = "11";
          }
          else if (dayText > 274) {
            day = dayText - 274;
            month = "10";
          }
          else if (dayText > 244) {
            day = dayText - 244;
            month = "09";
          }
          else if (dayText > 213) {
            day = dayText - 213;
            month = "08";
          }
          else if (dayText > 182) {
            day = dayText - 182;
            month = "07";
          }
          else if (dayText > 152) {
            day = dayText - 152;
            month = "06";
          }
          else if (dayText > 121) {
            day = dayText - 121;
            month = "05";
          }
          else if (dayText > 91) {
            day = dayText - 91;
            month = "04";
          }
          else if (dayText > 60) {
            day = dayText - 60;
            month = "03";
          }
          else if (dayText < 32) {
            month = "01";
            day = dayText;
          }
          else if (dayText > 31) {
            day = dayText - 31;
            month = "02";
          }
          this.user.dob = year + '/' + month + '/' + day
        }
      }


    },
  }
}
</script>

<style scoped>

</style>
